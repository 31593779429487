import React from "react"
import { graphql } from "gatsby"
import classNames from 'classnames'

import Layout from '../../components/layout/layout'
import PoemHeader from '../../components/poemHeader/poemHeader'

import styles from "./socialCard.module.scss"

function SocialCard({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  return (
    <Layout
      className={ classNames(styles.socialCard, styles[pageContext.variant]) }
    >
      <PoemHeader
        className={ classNames(styles.poemHeader, styles[pageContext.variant]) }
        title={ frontmatter.excerpt }
        titleFontSize={ frontmatter.excerptFontSize }
        titleClassName="h2"
        titleTag="h2"
        variant={ pageContext.variant }
      />
    </Layout>
  )
}

export default SocialCard

export const query = graphql`
  query($path: String!) {
    markdownRemark(fields: { socialCard: { eq: $path } }) {
      html
      fields { socialCard }
      frontmatter {
        contentFontSize
        date(formatString: "YYYY · MM · DD")
        excerpt
        excerptFontSize
        title
        titleFontSize
      }
    }
  }
`
