import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from "./poemHeader.module.scss"

function PoemHeader({
  attribution, className, date, format, tag: Tag, title, titleClassName, titleFontSize, titleTag: TitleTag, variant
}) {
  titleFontSize = titleFontSize ? `${titleFontSize}em` : null

  return (
    <Tag
      className={ classNames(styles.poemHeader, className, styles[variant], styles[format]) }
    >
      <div>
        { date &&
          <time>{ date }</time>
        }
        <TitleTag
          className={ titleClassName }
          dangerouslySetInnerHTML={ {__html: title } }
          style={ { fontSize: titleFontSize } }
        >
        </TitleTag>
        {
          attribution &&
          <div className={ styles.attribution}>{ attribution }</div>
        }
      </div>
    </Tag>
  )
}

PoemHeader.propTypes = {
  attribution: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  format: PropTypes.oneOf(['single']),
  tag: PropTypes.oneOf(['div', 'header', 'footer']),
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.oneOf(['h1', 'h2']),
  titleFontSize: PropTypes.number,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'div']),
  variant: PropTypes.oneOf(['light', 'dark'])
}

PoemHeader.defaultProps = {
  attribution: null,
  className: null,
  date: null,
  format: null,
  tag: 'div',
  titleClassName: 'h1',
  titleFontSize: null,
  titleTag: 'h1',
  variant: null
}

export default PoemHeader
